<template>
  <div class="home">
    <div class="header">
      <div class="topCon">
        <div class="img">
          <img src="@/assets/img/logo.png" />
        </div>
        <div class="list">
          <div
            v-for="(item, i) in list"
            :key="item.name"
            :class="{ color: listIndex == i }"
            @click="listClick(i, item)"
          >
            <template v-if="i == 2">
              <span class="val" v-if="userData.is_free_send == 1">{{
                lang_data == 1 ? item.name : item.name_en
              }}</span>
            </template>
            <template v-else>
              <span class="val">{{
                lang_data == 1 ? item.name : item.name_en
              }}</span>
            </template>
          </div>
        </div>
        <div class="right">
          <div class="phone">
            <el-dropdown>
              <span class="tel">
                <i
                  class="el-icon-phone-outline"
                  style="font-size: 22px; padding-right: 10px"
                ></i
                ><span v-if="listPhone.length > 0" style="font-size: 18px">{{
                  listPhone[0].mobile
                }}</span>
                <i class="el-icon-caret-bottom"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="item in listPhone" :key="item.id"
                  ><div class="factoryitem">
                    {{ item.name }}<br /><span class="fs16 b">{{
                      item.mobile
                    }}</span>
                  </div></el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="personal">
            <el-dropdown @command="dropdownClick">
              <span class="username">
                {{ usercenter.true_name || userData.true_name }}
                <el-badge
                  :value="
                    $store.state.wsUnreadCount.num > 0
                      ? $store.state.wsUnreadCount.num
                      : null
                  "
                  class="item"
                >
                  <img
                    @error="notFind($event, 80)"
                    :src="usercenter.photo || userData.photo"
                    alt=""
                  />
                </el-badge>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="1">
                  <span>{{
                    lang_data == 1 ? "个人中心" : "Personal Center"
                  }}</span>
                </el-dropdown-item>

                <el-dropdown-item command="2">{{
                  lang_data == 1 ? "消息列表" : "Message list"
                }}</el-dropdown-item>

                <el-dropdown-item command="3">
                  <span>{{ lang_data == 1 ? "退出登录" : "Sign Out" }}</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
    </div>
    <router-view :key="$route.fullPath" />
    <Msgbox v-if="msgboxShow" :msgboxShow="msgboxShow" @cancel="msgboxCancel" />
  </div>
</template>

<script>
import Msgbox from "@/components/msgbox.vue";
import lang from "element-ui/lib/locale/lang/en";
import locale from "element-ui/lib/locale";
import zhLocale from "element-ui/lib/locale/lang/zh-CN";
let ws;
export default {
  components: { Msgbox },
  data() {
    return {
      lang_data: localStorage.lang_data || 1,
      list: [
        {
          name: "订单管理",
          name_en: "Order",
          path: "order",
        },
        {
          name: "售后管理",
          name_en: "After-sales",
          path: "afterSales",
        },
        {
          name: "免费寄件",
          name_en: "Free delivery",
          path: "freedelivery",
        },
        {
          name: "积分商城",
          name_en: "Mall",
          path: "pointsMall",
        },
      ],
      listIndex: 0,
      listPhone: [],
      userData: {},
      msgboxShow: false,
    };
  },
  watch: {
    messageSending(data) {
      ws.send(data);
    },
    wsUnreadCount(val) {
      if (val > 0 && !this.msgboxShow) {
        this.notification();
      }
    },
    menuIndex(val) {
      this.listIndex = val;
    },
  },
  computed: {
    // 获取用户信息
    usercenter() {
      return this.$store.state.usercenter;
    },
    // 获取发送的消息
    messageSending() {
      return this.$store.state.messageSending;
    },
    // 监听菜单下标
    menuIndex() {
      return this.$route.meta.type;
    },
    wsUnreadCount() {
      return this.$store.state.wsUnreadCount.num;
    },
  },

  mounted() {
    if (this.lang_data == 1) {
      localStorage.lang_data = 1;
      locale.use(zhLocale);
      document.title = "美鸣齿科医生端";
    } else {
      localStorage.lang_data = 0;
      locale.use(lang);
      document.title = "Merlin dental lab";
    }
    // 获取用户信息
    if (localStorage.userData) {
      this.userData = JSON.parse(localStorage.userData);
    }
    // 获取工厂电话列表
    this.getClinicFactory();

    this.ws();
    this.getunreadlist();
    this.listIndex = this.$route.meta.type;
  },
  methods: {
    msgboxCancel() {
      this.msgboxShow = false;
      if (this.$store.state.wsUnreadCount.num > 0) {
        this.notification();
      }
    },
    notification() {
      let val1 =
        "<div style='font-size:15px;margin-bottom:10px'><b>你有新的消息，请及时查看！！！</div> <span  class='mesClass' style='cursor: pointer;padding: 3px 10px;background: #409EFF;border-radius: 20px;color: #fff;'>查看</span>";
      let val2 =
        "<div style='font-size:15px;margin-bottom:10px'><b>You have the latest news！！！</div> <span  class='mesClass' style='cursor: pointer;padding: 3px 10px;background: #409EFF;border-radius: 20px;color: #fff;'>To view</span>";
      this.$notify.closeAll();
      this.$notify({
        title: "提示",
        dangerouslyUseHTMLString: true,
        duration: 0,
        message: this.lang_data == 1 ? val1 : val2,
      });
      setTimeout(() => {
        document
          .getElementsByClassName("mesClass")[0]
          .addEventListener("click", () => {
            this.msgboxShow = true;
            this.$notify.closeAll();
          });
      }, 1000);
    },
    // 获取未读消息
    async getunreadlist() {
      return this.$axios
        .post("/orders/order_message/getunreadlist")
        .then((res) => {
          if (res.code == 1) {
            this.$store.dispatch("msessageMethod", {
              num: res.data.length,
              arr: res.data,
            });
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    ws() {
      let that = this;
      let serverUrl =
        window.location.host == "doctor.xinmk.cn" ||
        window.location.host == "doctor.meimingdental.com"
          ? "wss://doctor.xinmk.cn/wss"
          : "wss://testdoctor.xinmk.cn/wss";
      ws = new WebSocket(serverUrl);
      ws.onopen = () => {
        // 连接成功
        console.log("连接成功");
        let obj = {
          message_type: "doctor_login",
          token: localStorage.token,
        };
        setTimeout(() => {
          ws.send(JSON.stringify(obj));
        }, 100);
        that.heartbeatTimer = setInterval(() => {
          ws.send(JSON.stringify({ message_type: "pong" }));
        }, 10000);
      };
      ws.onmessage = async (e) => {
        //接收消息
        let data = JSON.parse(e.data);
        if (data.message_type == "send_order_message") {
          this.$store.dispatch("msessageData", data.data);
          that.getunreadlist();
        }
      };
      ws.onclose = () => {
        clearTimeout(that.heartbeatTimer);
        console.log("连接失败");
        //连接失败
        that.ws();
      };
    },
    dropdownClick(val) {
      if (val == 1) {
        this.$router.push(
          {
            name: "userCenter",
          },
          () => {}
        );
      } else if (val == 2) {
        this.msgboxShow = true;
      } else if (val == 3) {
        this.$axios.get("/logout").then(() => {
          //清空im的链接session
          localStorage.removeItem("userData");
          localStorage.removeItem("is_admin");
          localStorage.removeItem("token");
          this.$store.dispatch("clearLoginInfo");
          this.$router.push("/");
        });
      }
    },
    listClick(i, item) {
      this.listIndex = i;
      this.$router.push(
        {
          name: item.path,
        },
        () => {}
      );
    },

    // 工厂电话列表请求
    getClinicFactory() {
      this.$axios
        .get("/setting/factory/getClinicFactoryList", {
          params: { clinic_id: 0 },
        })
        .then((res) => {
          if (res.code != 1) {
            this.$message.error(res.msg);
            return;
          }
          this.listPhone = res.data.list;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .header {
    background: #0d0c1f;

    .topCon {
      width: 85%;
      height: 70px;
      margin: 0 auto;
      box-sizing: border-box;
      display: flex;
      .img {
        overflow: hidden;
        img {
          height: 70px;
        }
      }
      .list {
        padding-left: 50px;
        font-size: 18px;
        display: flex;
        align-items: center;
        font-weight: 600;
        color: #979797;
        .val {
          margin-right: 30px;
          cursor: pointer;
        }
        div:hover {
          color: #409eff;
        }
        .color {
          color: #409eff;
        }
      }
      .right {
        margin-left: auto;
        display: flex;

        .phone {
          float: right;
          display: flex;
          align-items: center;

          .tel {
            color: #b8b8b8;
            position: relative;
            height: 48px;
            display: flex;
            align-items: center;
            line-height: 55px;
            font-weight: normal;
            .el-icon-caret-bottom {
              position: absolute;
              bottom: -3px;
              right: -3px;
              transform: rotate(-48deg);
            }
            .telico {
              background-image: url("/static/img/icon/telico.png");
              display: inline-block;
              width: 28px;
              height: 28px;
              margin-right: 8px;
            }
          }
        }
        .personal {
          display: flex;
          align-items: center;
          margin-right: 10px;
          .username {
            img {
              width: 40px;
              height: 40px;
              margin-left: 15px;
              border-radius: 50%;
            }
            font-size: 18px;
            color: #ededed;
            font-weight: normal;
            display: flex;
            align-items: center;
            cursor: pointer;
            margin-left: 50px;
          }
        }
      }
    }
  }
}
</style>